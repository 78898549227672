import { clevertapEvent } from 'utils/clevertap';
import { emailLogin, fbLogin, googleLogin } from 'lib/api/authNext';

import { EmailLoginProps, ErrorResponse, SocialLoginProps } from './types';

export const sendClevertapEvent = {
  loginMethod: (method: string) => {
    clevertapEvent('Login Selected', {
      'Login Method': method,
    });
  },
  loginSuccess: (method: string) => {
    clevertapEvent('Login Successful', {
      'Login Method': method,
    });
  },
  loginFailed: (method: string, message: string) => {
    clevertapEvent('Login Failed', {
      'Login Method': method,
      Message: message,
    });
  },
};

export const submitEmailLogin = async ({
  username,
  password,
  verificationToken,
  recaptchaVersion,
}: EmailLoginProps) => {
  if (!username || !password) {
    throw new Error('Username and password are required');
  }

  const { data } = await emailLogin({
    username,
    password,
    verificationToken,
    recaptchaVersion,
  });

  if (!data) {
    throw new Error('Could not perform submit');
  }

  return data;
};

export const submitFacebookLogin = async ({
  accountId,
  accessToken,
}: SocialLoginProps) => {
  if (!accountId || !accessToken) {
    throw new Error('Account ID and access token are required');
  }

  const { data } = await fbLogin({ id: accountId, key: accessToken });

  if (!data) {
    throw new Error('Could not perform submit');
  }

  return data;
};

export const submitGoogleLogin = async ({
  accountId,
  accessToken,
}: SocialLoginProps) => {
  if (!accountId || !accessToken) {
    throw new Error('Account ID and access token are required');
  }

  const { data } = await googleLogin({ id: accountId, key: accessToken });

  if (!data) {
    throw new Error('Could not perform submit');
  }

  return data;
};

export const formatErrorMessage = (error: unknown): string => {
  let message = '';

  const errorResponse = error as ErrorResponse;
  const errorMessage = errorResponse?.response?.data?.message;

  if (errorMessage) {
    message = errorMessage;
  } else if (error instanceof Error) {
    message = error.message;
  }
  return message;
};
