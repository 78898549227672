import Link from 'next/link';
import Text from '@deprecated-ui/common/Text';
import Button from '@deprecated-ui/common/Button';
import color from '@deprecated-ui/_style/color';
import { useEffect, useRef, useState } from 'react';
import If from 'global/ErrorHandlers/If';
import Image from 'global/Image';
import {
  LS_EMAIL_REGISTER,
  LS_PHONE_REGISTER,
  registerTypes,
} from 'features/register/constants';
import useSecuritiesCredentialStore from 'global/Securities/stores/useSecuritiesCredentialStore';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  EmailContainer,
  Input,
  InputContainer,
  PasswordContainer,
} from '../styled';
import { useLoginState, useLoginAction } from '../store';
import { sendClevertapEvent } from '../helpers';
import { useLoginEmailMutation } from '../hooks/useLoginMutation';
import { DATA_CY_LOGIN_COMPONENTS as DATA_CY } from '../__tests__/constants';

interface FormElements extends HTMLFormControlsCollection {
  username: HTMLInputElement;
  password: HTMLInputElement;
}

interface EmailPasswordFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

function EmailLogin() {
  const buttonEl = useRef<HTMLButtonElement | null>(null);
  const loginMutation = useLoginEmailMutation();
  const { isLoading } = useLoginState();
  const { startLoading, setEmailLoginProps } = useLoginAction();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [showIcon, setShowIcon] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');

  const onFormSubmit = async (
    event: React.FormEvent<EmailPasswordFormElement>,
  ) => {
    event.preventDefault();
    startLoading();

    useSecuritiesCredentialStore.getState().loadLogout();
    sendClevertapEvent.loginMethod(registerTypes.EMAIL);

    const { elements } = event.currentTarget;
    try {
      const verificationToken2 = await executeRecaptcha('login_browser');

      await loginMutation.mutateAsync({
        username: elements.username.value,
        password: elements.password.value,
        verificationToken: verificationToken2,
        recaptchaVersion: 'RECAPTCHA_VERSION_3',
      });
    } catch (err) {
      // Proceed username and password to robot verification
      setEmailLoginProps({
        username: elements.username.value,
        password: elements.password.value,
      });
      console.error(err);
    }
  };

  const updateShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    const storedEmail = localStorage.getItem(LS_EMAIL_REGISTER);
    const storedPhone = localStorage.getItem(LS_PHONE_REGISTER);
    if (storedEmail) {
      setUsername(storedEmail);
      setShowIcon(true);
    }

    if (storedPhone) {
      setShowIcon(true);
    }

    return () => {
      localStorage.removeItem(LS_EMAIL_REGISTER);
      localStorage.removeItem(LS_PHONE_REGISTER);
    };
  }, []);

  useEffect(() => {
    if (buttonEl?.current) {
      buttonEl.current.setAttribute('type', 'submit');
    }
  }, [buttonEl]);

  return (
    <form onSubmit={onFormSubmit} data-cy={DATA_CY.LOGIN_FORM}>
      <EmailContainer showIcon={showIcon}>
        <InputContainer mt="5px">
          <Input
            disabled={isLoading}
            id="username"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setUsername(e.target.value)
            }
            placeholder="Email or username"
            type="text"
            value={username}
            data-cy={DATA_CY.LOGIN_FORM_USERNAME}
            required
          />
        </InputContainer>
        <If condition={showIcon}>
          <button type="button" data-cy={DATA_CY.LOGIN_FORM_USERNAME_ICON}>
            <Image src="/icon/register/input-success.svg" alt="" />
          </button>
        </If>
      </EmailContainer>

      <PasswordContainer>
        <InputContainer mt="28px">
          <Input
            disabled={isLoading}
            id="password"
            name="password"
            placeholder="Password"
            type={showPassword ? 'text' : 'password'}
            data-cy={DATA_CY.LOGIN_FORM_PW}
            required
          />
        </InputContainer>
        <button
          onClick={updateShowPassword}
          type="button"
          data-cy={DATA_CY.LOGIN_FORM_PW_SHOW}
        >
          <Image
            src={
              !showPassword
                ? '/icon/register/icon-show.svg'
                : '/icon/register/icon-hide.svg'
            }
            alt=""
          />
        </button>
      </PasswordContainer>
      {/* @ts-expect-error */}
      <Text pt="10px" color={color.black} size="12px">
        Forgot your password?{' '}
        <Link href="/forgotpassword">
          <Text as="a" size="13px" data-cy={DATA_CY.LOGIN_FORGOT_PW_LINK}>
            Click here
          </Text>
        </Link>
      </Text>

      <Button
        height="44px"
        id="email-login-button"
        loading={isLoading}
        // @ts-expect-error
        mt="24px"
        ref={buttonEl}
        type="primary"
        data-cy={DATA_CY.LOGIN_FORM_SUBMIT}
        block
      >
        <Text family="bold" color={color.light}>
          {isLoading ? 'Logging In . . . ' : 'Login'}
        </Text>
      </Button>
    </form>
  );
}

export default EmailLogin;
